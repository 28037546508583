import Header from "../Header";
import SearchBar from "../search-engine/SearchBar";
import jwt from "jwt-decode";
import {checkPathName} from "../../global";
import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import dataProvider from "../../provider/api-interface";
import {Link} from "react-router-dom";


import Accessories from "../../media/images/Accessories.png";
import BestSelection from "../../media/images/BestSelection.png";
import BabyCare from "../../media/images/BabyCare.png";
import Skincare from "../../media/images/Skincare.png";
import BabyBoy from "../../media/images/BabyBoy.png";
import BabyGirl from "../../media/images/BabyGirl.png";


import './Categories.css';
import headerBackground from "../../media/images/GiftlistHeader.png";

import FooterDior from "./FooterDior";

function Categories() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const [searchParams, setSearchParams] = useSearchParams();

    const [wishlist, setWishlist] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [products, setProducts] = useState<any>(null);

    const listCode = searchParams.get('id');
    console.log(listCode, 'listCode in categories page')

    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    //Use anywhere
    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const [categoriesCount, setCategoriesCount] = useState<any>(6);

    const getCategories = async () => {
        //console.log(user, 'user');
        const categories = await dataProvider.get(`main-category?filter={"dior_locale_id" : ${parseInt(user.locale_id)}}`, token);
        if(categories !== null) {
            //console.log(categories, 'data in categories page');
            //console.log(categories.length, 'total in categories page');
            setCategoriesCount(categories.length);
        }
    };

    useEffect(() => {
        //getCategories();
    });
    //console.log(wishlist, 'wishlist in catalogue page')
    //console.log(wishlist !== null ? wishlist.products : null, 'wishlistProducts in catalogue page')
    //console.log(products, 'products in catalogue page')
    let wishlistUrl = null;
    if(user.role === 2 || user.role === 3){
        wishlistUrl = `/${pathName}/registry/${listCode}`;
    }
    if(categoriesCount > 3) {
        return (
            <div className="Categories">
                <Header /*hideLanguageSelect={user.role === 2}*/ wishlistUrl={wishlistUrl} link={`/${pathName}/registry/${listCode}`}
                        textLink={t('header.home')}/>
                <div className="row no-gutter">
                    <div className="CategoriesContainer MultipleCategories col-12 d-md-flex d-none">
                        <div className="CategoriesTitle">
                            <h1>{t('categoriesTitle')}</h1>
                        </div>
                        <div className="TopCategoriesContainer">
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=0`}
                                  style={{background: `url(${BestSelection}),lightgray 50%`}} className="TopCategory1">
                                <div className="CategoryNameContainer">
                                    <span>{t('bestSelection')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=3`}
                                  style={{background: `url(${Skincare}),lightgray 50%`}} className="TopCategory2">
                                <div className="CategoryNameContainer">
                                    <span>{t('skincare')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=2`} className="TopCategory3"
                                  style={{background: `url(${BabyCare}),lightgray 50%`}}>
                                <div>
                                    <div className="CategoryNameContainer">
                                        <span>{t('babyCare')}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="BottomCategoryContainer">
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=4`}
                                  style={{backgroundImage: `url(${BabyBoy})`, backgroundColor: '#f2f2f4', backgroundSize: 'contain'}} className="BottomCategory2">
                                <div className="CategoryNameContainer">
                                    <span>{t('babyBoy')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=5`}
                                  style={{backgroundImage: `url(${BabyGirl})`,backgroundColor: '#f2f2f4', backgroundSize: 'contain'}} className="BottomCategory3">
                                <div className="CategoryNameContainer">
                                    <span>{t('babyGirl')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=1`}
                                  style={{background: `url(${Accessories}),lightgray 50%`, backgroundSize: 'cover'}} className="BottomCategory1">
                                <div className="CategoryNameContainer">
                                    <span>{t('accessories')}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="CategoriesContainer MobileCategories MultipleCategories col-12 d-md-none d-flex">
                        <div className="CategoriesTitle">
                            <h1>{t('categoriesTitle')}</h1>
                        </div>
                        <div className="TopCategoriesContainer">
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=0`}
                                  style={{background: `url(${BestSelection}),lightgray 50%`}} className="TopCategory1">
                                <div className="CategoryNameContainer">
                                    <span>{t('bestSelection')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=1`}
                                  style={{background: `url(${Accessories}),lightgray 50%`}} className="TopCategory2">
                                <div className="CategoryNameContainer">
                                    <span>{t('accessories')}</span>
                                </div>
                            </Link>
                        </div>
                        <div className="MiddleCategoriesContainer">
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=3`}
                                  style={{background: `url(${Skincare}),lightgray 50%`}} className="MiddleCategory1">
                                <div className="CategoryNameContainer Skincare">
                                    <span>{t('skincare')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=2`} className="MiddleCategory2"
                                  style={{background: `url(${BabyCare}),lightgray 50%`}}>
                                <div>
                                    <div className="CategoryNameContainer">
                                        <span>{t('babyCare')}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="BottomCategoryContainer">
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=4`}
                                  style={{backgroundImage: `url(${BabyBoy})`, backgroundColor: '#f2f2f4', backgroundSize: 'contain'}} className="BottomCategory1">
                                <div className="CategoryNameContainer">
                                    <span>{t('babyBoy')}</span>
                                </div>
                            </Link>
                            <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=5`}
                                  style={{backgroundImage: `url(${BabyGirl})`,backgroundColor: '#f2f2f4', backgroundSize: 'contain'}} className="BottomCategory2">
                                <div className="CategoryNameContainer">
                                    <span>{t('babyGirl')}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <FooterDior showDisclaimer={false}/>
            </div>
        );
    }

    return (
        <div className="Categories">
            <Header /*hideLanguageSelect={user.role === 2}*/ wishlistUrl={wishlistUrl} link={`/${pathName}/registry/${listCode}`}
                    textLink={t('header.home')}/>
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
                <div className="CategoriesContainer col-12 col-lg-6">
                    <div className="CategoriesTitle">
                        <h1>{t('categoriesTitle')}</h1>
                    </div>
                    <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=2`}
                          className="TopCategoryContainer">
                        <div style={{background: `url(${BabyCare}),lightgray 50%`}} className="TopCategory">
                            <div className="CategoryNameContainer">
                                <span>{t('babyCare')}</span>
                            </div>
                        </div>
                    </Link>
                    <div className="BottomCategoryContainer">
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=1`}
                              style={{background: `url(${Accessories}),lightgray 50%`}} className="BottomCategory1">
                            <div className="CategoryNameContainer">
                                <span>{t('accessories')}</span>
                            </div>
                        </Link>
                        <Link to={`/${pathName}/registry/catalogue?id=${listCode}&categoryId=3`}
                              style={{background: `url(${Skincare}),lightgray 50%`}} className="BottomCategory2">
                            <div className="CategoryNameContainer">
                                <span>{t('skincare')}</span>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>
    );

}

export default withTranslation()(Categories);