import React, { useEffect, useState } from 'react';
//import 'react-responsive-carousel/lib/styles/carousel.min.css';
//import { Carousel } from 'react-responsive-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './Slider.css';

interface GalleryImage {
    image_thumb_uri: string;
}

interface ImageSliderProps {
    imagesSlide: GalleryImage[] | [];
    altTitle: string;
}

function ImageSlider({ imagesSlide, altTitle }: ImageSliderProps) {
    const [items, setItems] = useState<GalleryImage[] | []>([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        if(imagesSlide.length > 0){
            setItems(imagesSlide);
        }
    }, [imagesSlide]);

    /*const renderCustomThumbs = () => {
        return items.map((item, i) => {
            return (
                <div key={i} style={{ padding: '1px' }}>
                    <img className="img-fluid" src={item.image_thumb_uri} alt={altTitle} />
                </div>
            );
        });
    }

    const mainGroupId = 'Main';
    const desktopSettings = {
        centerMode: true,
        centerSlidePercentage: 40,
        transitionTime: 500,
        swipeScrollTolerance: 5,
        emulateTouch: true,
        showArrows: false,
        showStatus: false,
        showThumbs: true,
        thumbWidth: 100,
        showIndicators: false,
        renderThumbs: renderCustomThumbs,
    }

    const mobileSettings = {
        centerMode: true,
        centerSlidePercentage: 80,
        transitionTime: 500,
        swipeScrollTolerance: 5,
        emulateTouch: true,
        showArrows: false,
        showStatus: false,
        showThumbs: false,
        showIndicators: false
    }

    const settings = isMobile ? mobileSettings : desktopSettings;

    const sliderStyle = {height: '300px', width: 'auto', margin: '1px', backgroundColor: '#f2f2f4'};*/
    const slideStyle = { padding: '1px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
         };

    let swiperProps: any = {
        slidesPerView:  1,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        style: slideStyle,
        modules: [Pagination],
        className: "mySwiper"
    }

    if(isMobile) {
        swiperProps = {
            slidesPerView:  1,
            spaceBetween: 1,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            style: slideStyle,
            modules: [Pagination],
            className: "mySwiper"
        }
    }
    return (
        <Swiper
            {...swiperProps}
        >
            {items.map((item, i) => (
                <SwiperSlide key={i}>
                    <img src={item.image_thumb_uri}/>
                </SwiperSlide>
            ))}
            <div className="swiper-pagination"></div>
        </Swiper>
    );
}

/*interface ItemProps {
    item: GalleryImage;
}

function Item({ item }: ItemProps) {
    //return (
    //    <div style={{ padding: '1px' }}>
    //        <img className="img-fluid" src={item.image_thumb_uri} alt="" />
    //    </div>
    //);

    return (
        <SwiperSlide style={{height: '300px', width: 'auto', margin: '1px', backgroundColor: '#f2f2f4'}}>
            <div style={{ padding: '1px',
                backgroundImage: `url(${item.image_thumb_uri})`,
                height: '100%',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: '#f2f2f4' }}>
            </div>
        </SwiperSlide>
    )
}*/

export default ImageSlider;
