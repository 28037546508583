import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {Accordion, AccordionDetails, AccordionSummary, InputAdornment} from "@mui/material";
import {Modal} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";

import jwt from 'jwt-decode';

import dataProvider from "../../provider/api-interface";

import './style.css';
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import WishlistsPreview from "../pages/WishlistsPreview";
import GifteesPreview from "../pages/GifteesPreview";
import ProductsList from "../pages/ProductsList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from '@mui/icons-material/Check';

const SearchBar = (props: any) => {
    //console.log(props, 'props in searchbar component')
    const [searchParams, setSearchParams] = useSearchParams();

    const [wishlists, setWishlists] = useState<any>( null);
    const [webWishlists, setWebWishlists] = useState<any>( null);
    const [giftees, setGiftees] = useState<any>(null);

    const [wishlist, setWishlist] = useState<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [routeType, setRouteType] = useState<any>(null);
    const [categoryId, setCategoryId] = useState<any>(null);


    const [searchLabel, setSearchLabel] = useState<string>('Search...');
    const [total, setTotal] = useState<any>(null);
    const [totalString, setTotalString] = useState<string>('');

    const [searchString, setSearchString] = useState<string>('');
    const [isSortActive, setIsSortActive] = useState<boolean>(false);
    const [sortItems, setSortItems] = useState<any>(null);
    const [sortBy, setSortBy] = useState<any>('');
    const [activeSort, setActiveSort] = useState<any>('');

    const [activeSizeFilter, setActiveSizeFilter] = useState<any>([]);
    const [activeCategoryFilter, setActiveCategoryFilter] = useState<any>([]);
    const [activeColorFilter, setActiveColorFilter] = useState<any>([]);
    const [activeGenderFilter, setActiveGenderFilter] = useState<any>([]);

    const [filterCategoryItems, setFilterCategoryItems] = useState<any>(null);
    const [filterColorItems, setFilterColorItems] = useState<any>(null);
    const [filterGenderItems, setFilterGenderItems] = useState<any>(null);
    const [filterSizeItems, setFilterSizeItems] = useState<any>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
    const [filterBy, setFilterBy] = useState<any>('');

    const [activeBoutiqueFilter, setActiveBoutiqueFilter] = useState<any>('');
    const [filterBoutiqueItems, setFilterBoutiqueItems] = useState<any>(null);

    const [activeLocaleFilter, setActiveLocaleFilter] = useState<any>(null);
    const filterLocaleItems = [
        {id: 1, name: 'France'},
        {id: 2, name: 'UK'},
        //{id: 3, name: 'USA'},
        //{id: 4, name: 'UAE'}
    ];

    const [modalType, setModalType] = useState<string>('sort'); // ['sort', 'filter'
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [languageParams, setLanguageParams] = useState<any>('');
    const [userLanguage, setUserLanguage] = useState<any>(null);

    const [filtersQueryString, setFiltersQueryString] = useState<any>('');

    useEffect(() => {
        if(props.searchQuery === 'products') {
            let filterParams = searchParams.get('filter');
            console.log(filterParams, 'filterParams')
            if(filterParams !== null) {
                setFiltersQueryString('filter='+filterParams);
            }
        }
    }, []);



    const [user, setUser] = useState<any>(jwt(props.token));
    //console.log(user, 'user in searchbar component');

    const getSubCategories = async (categoryId: any, localeId: any = null) => {
        const internalLanguageParams = localeId !== null ? `,"chosen_dior_locale_id":${parseInt(localeId)}` : `,"chosen_dior_locale_id":${parseInt(props.wishlist.boutique.dior_locale_id)}`;
        const response = await dataProvider.get(`sub-category?filter={"main_category_id": ${parseInt(categoryId)}, "dior_locale_id" : ${props.wishlist.boutique.dior_locale_id}${languageParams !== '' ? languageParams : internalLanguageParams}}`, props.token);
        if(response !== null) {
            //console.log(response, 'response in getSubCategories');
            setFilterCategoryItems(response);
        } else {
            console.log('No categories found');
        }

    }

    const getColors = async (categoryId: any) => {
        //console.log(products, 'products for color');
        //console.log(filterColorItems, 'filterColorItems');
        const response = await dataProvider.get(`colors?filter={"main_category_id": ${parseInt(categoryId)}, "dior_locale_id" : ${props.wishlist.boutique.dior_locale_id}}`, props.token);
        if(response !== null) {
            console.log(response, 'response in colors');
            const colors: any = [];
            if(response.length > 0) {
                response.map((item: any) => {
                    if(item.tracking_variant !== null && item.tracking_variant !== '' && item.tracking_variant !== undefined){
                        colors.push(item.tracking_variant);
                    }
                });
                setFilterColorItems(colors);
            }
        } else {
            console.log('No colors found');
        }
    }

    const getGenders = async (categoryId: any) => {
        //console.log(products, 'products for color');
        //console.log(filterColorItems, 'filterColorItems');
        const response = await dataProvider.get(`genders?filter={"main_category_id": ${parseInt(categoryId)}, "dior_locale_id" : ${props.wishlist.boutique.dior_locale_id}}`, props.token);
        if(response !== null) {
            console.log(response, 'response in genders');
            const genders: any = [];
            if(response.length > 0) {
                response.map((item: any) => {
                    if(item.category_id.includes('bebesgarcons')){
                        genders.push('bebesgarcons');
                    }else if(item.category_id.includes('bebefilles')){
                        genders.push('bebefilles');
                    }
                });
                let gendersUnique = genders.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
                setFilterGenderItems(gendersUnique);
                console.log(genders, 'processed genders')
                console.log(gendersUnique, 'genders unique');
            }
        } else {
            console.log('No genders found');
        }
    }

    const getBoutiques = async () => {
        const filterString = user.role === 0 ? `?filter={"dior_locale_id" : ${user.dior_locale_id}}` : '';
        const response = await dataProvider.get(`boutique${filterString}`, props.token);
        if(response !== null) {
            //console.log(response, 'response in getBoutiques');
            if(response.total > 1) {
                setFilterBoutiqueItems(response);
                setIsFilterActive(true);
            }
        } else {
            console.log('No boutiques found');
        }
    }

    const getSizesColorsGendersAndCategories = async (languageId: any = null) => {
       const response = await dataProvider.get(`product?filter={"chosen_dior_locale_id": "${languageId !== null ? languageId : 2}","dior_locale_id": "${props.wishlist.boutique.dior_locale_id}","wishlist_id": "${props.wishlist.id}"${languageParams}}&perPage=-1`, props.token);
        if (response !== null) {
            console.log(response, 'response in getSizes');
            //Remove comment if filter size is needed
           //setFilterSizeItems(response.size_labels);
           setFilterCategoryItems(response.sub_categories);
           setFilterColorItems(response.colors);
           setFilterGenderItems(response.genders)
       } else {
              console.log('No sizes found');
       }
    }


    useEffect(() => {
        //console.log(activeBoutiqueFilter, 'activeBoutiqueFilter in use effect');
        if(props.searchQuery === 'wishlist') {
            setIsSortActive(true);
            //getBoutiques();
            if(user.role === 1) {
                setIsFilterActive(true);
                if(props.activeLocaleId !== null) {
                    handleActiveFilters(props.activeLocaleId)
                }
            }
            setSortItems(['Creation date', 'Name']);
            setWishlists(props.elements);
            setIsLoading(props.isLoading);
            setTotalString(props.elements.length+' '+t('filterSort.wishlists'));
            setSearchLabel(t('searchWishlistLabel'));
            //console.log(wishlists, 'wishlists');
        }

        if(props.searchQuery === 'webWishlist') {
            setIsSortActive(true);
            //getBoutiques();
            if(user.role === 1) {
                setIsFilterActive(true);
                if(props.activeLocaleId !== null) {
                    handleActiveFilters(props.activeLocaleId)
                }
            }
            setSortItems(['Creation date', 'Name']);
            setWebWishlists(props.elements);
            setIsLoading(props.isLoading);
            setTotalString(props.elements.length+' '+t('filterSort.wishlists'));
            setSearchLabel(t('searchWishlistLabel'));
            //console.log(wishlists, 'wishlists');
        }

        if(props.searchQuery === 'giftee') {
            setIsSortActive(true);
            //getBoutiques();
            if(user.role === 1) {
                setIsFilterActive(true);
                console.log(props.activeLocaleId, 'props.activeLocaleId');
                if(props.activeLocaleId !== null) {
                    handleActiveFilters(props.activeLocaleId)
                }
            }
            setSortItems(['Name']);
            setGiftees(props.elements);
            setTotalString(props.elements.length+' '+t('filterSort.giftees'));
            setIsLoading(props.isLoading);
            setSearchLabel(t('searchClientLabel'));
            //console.log(giftees, 'giftees');
        }

        if(props.searchQuery === 'products') {
            //let language = null;
            //if(user.role === 2) {
            let chosenLocale = sessionStorage.getItem('locale') !== null ? sessionStorage.getItem('locale') : user.locale_id;
            if(chosenLocale === undefined ||
                chosenLocale === null ||
                chosenLocale === '' ||
                chosenLocale === 'null' ||
                chosenLocale === 'undefined') {
                chosenLocale = 2;
            }

            setLanguageParams(`,"chosen_dior_locale_id":${parseInt(chosenLocale)}`);
                setUserLanguage(parseInt(chosenLocale));
                let language = parseInt(chosenLocale);
            //}

            if(props.routeType === 'catalogue') {
                getSubCategories(props.category, parseInt(chosenLocale));
                getColors(props.category);
                getGenders(props.category);
                setCategoryId(parseInt(props.category));
                setTotal(props.products !== null ? props.products.total : '');
                setTotalString(props.products.total+' '+t('filterSort.products'));
                setIsSortActive(true);
                setSortItems(['Price - High first', 'Price - Low first']);

                if(props.products !== null && props.products.size_labels !== null) {
                    setIsFilterActive(true);
                    //Remove comment if filter size is needed
                    //setFilterSizeItems(props.products.size_labels);
                }
            } else {
                getSizesColorsGendersAndCategories(language);
                setIsFilterActive(true);
                setTotal(props.products !== null ? props.products.total : '');
                setTotalString(t('filterSort.products')+' '+props.products.total);
                setIsSortActive(false);
            }

            setRouteType(props.routeType);
            setWishlist(props.wishlist);
            setWebWishlists(props.wishlist);

            if(props.activeFilters !== undefined && props.activeFilters !== null) {
                let activeFilters = JSON.parse(props.activeFilters);
                console.log(activeFilters, 'activeFilters in products');
                let sortString = '';
                if (props.activeSort !== undefined && props.activeSort !== null) {
                    sortString = props.activeSort;
                }
                handleActiveFilters(activeFilters, sortString);
            }

            setProducts(props.products);

            setIsLoading(props.isLoading);
            setSearchLabel(t('searchProductLabel'));
            //console.log(products, 'products in searchbar component');
        }

    }, [props]);


    const handleActiveFilters = (activeFilters: any, sortString: any = '') => {

        //console.log(activeFilters, 'activeFilters');

        let searchString: any = '';

        if(activeFilters !== null) {
            if(activeFilters.sub_category_id !== undefined) {
                console.log(activeFilters.sub_category_id, 'activeFilters.sub_category_id')
                if(activeCategoryFilter.length === 0) {
                    setActiveCategoryFilter(activeFilters.sub_category_id);
                }
            }

            if(activeFilters.color !== undefined) {
                if(activeColorFilter.length === 0) {
                    activeFilters.color.map((item: any) => {
                        activeColorFilter.push(`"${item}"`);
                    });
                    setActiveColorFilter(activeColorFilter);
                }
            }

            if(activeFilters.gender !== undefined) {
                if(activeGenderFilter.length === 0) {
                    activeFilters.gender.map((item: any) => {
                        activeGenderFilter.push(`"${item}"`);
                    });
                    setActiveGenderFilter(activeGenderFilter);
                }
            }

            if(activeFilters.size_label !== undefined) {
                console.log(activeFilters.size_label, 'activeFilters.size_label')
                let sizeLabels = activeFilters.size_label;
                sizeLabels.map((item: any) => {
                    activeSizeFilter.push(`"${item}"`);
                });
                setActiveSizeFilter(activeSizeFilter);
            }

            if(activeFilters.q !== undefined) {
                console.log(activeFilters.q, 'activeFilters.q')
                searchString = activeFilters.q;
                setSearchString(searchString);
            }

            if(props.searchQuery !== 'products') {
                if (user.role === 1) {
                    if (activeFilters !== null) {
                        let filterString = '';
                        if(props.searchQuery === 'giftee') {
                            filterString = `,"dior_locale_id" : ${activeFilters}`;
                        } else {
                            filterString = `,"wishlist_dior_locale_id" : ${activeFilters}`;
                        }
                        setActiveLocaleFilter(activeFilters);
                        search('', '', filterString);
                    } else {
                        setActiveLocaleFilter(null);
                    }

                    /*if(props.activeBoutiqueId !== null) { //TODO activate for SA
                        setActiveBoutiqueFilter(props.activeBoutiqueId);
                    } else {
                        setActiveBoutiqueFilter('');
                    }*/

                }
            }
        }

        if(sortString !== '') {
            if(sortString === '["price_value","DESC"]') {
                setActiveSort('Price - High first');
            } else if(sortString === '["price_value","ASC"]') {
                setActiveSort('Price - Low first');
            }
        }
        //console.log(wishlist, 'wishlist in handleActiveFilters');
        //search(searchString, sortString, filterString);

    }

    const search = async (string: any, sortString: any, filterString: any) => {
        //console.log(filterString, 'filterString in search');
        setFilterBy(filterString);
        setSearchString(string)
        setSortBy(sortString);
        let sort = '';
        let queryString = '';

        if(filterString === '') {
            if(props.setFilters !== undefined) {
                props.setFilters(null);
            }
        }

        if(sortString === '') {
            if(props.setSort !== undefined) {
                props.setSort(null);
            }
        }

        if(string !== '') {
            queryString = `,"q" : "${string}"`;
        }

        if(sortString !== '') {
            sort = `&sort=${sortString}`;
        }

        if(string.length > 2) {
            let response: any = '';
            if(props.searchQuery === 'products') {
                if(routeType == 'catalogue') {
                    props.setQuery(`?filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`);
                    //response = await dataProvider.get(`product?filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`, props.token);
                    setFiltersQueryString(`filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"main_category_id": ${parseInt(props.category)}${queryString}${filterString}${languageParams}}&perPage=-1${sort}`);
                } else {
                    props.setQuery(`?filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`);
                    //response = await dataProvider.get(`product?filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`, props.token);
                    setFiltersQueryString(`filter={"dior_locale_id" : ${wishlist.boutique.dior_locale_id},"wishlist_id": "${wishlist.id}"${queryString}${filterString}${languageParams}}&perPage=-1`)
                }
            }

            if(props.searchQuery === 'wishlist'){
                //let filterWishlistString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterWishlistString = parseInt(user.role) === 0 ? `,"wishlist_dior_locale_id" : ${user.dior_locale_id}` : '';
                const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_dior_locale_id" : ${user.dior_locale_id}` : '';
                response = await dataProvider.get(`${props.searchQuery}?filter={"created_by": [0,1],"status": 1, "q": "${string}"${filterWishlistString}${filterString}${filterByRole}}&perPage=-1${sort}`, props.token);
            }

            if(props.searchQuery === 'webWishlist'){
                //let filterWishlistString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterWishlistString = parseInt(user.role) === 0 ? `,"wishlist_dior_locale_id" : ${user.dior_locale_id}` : '';
                const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_dior_locale_id" : ${user.dior_locale_id}` : '';
                response = await dataProvider.get(`wishlist?filter={"status": 1,"created_by": 2, "q": "${string}"${filterWishlistString}${filterString}${filterByRole}}&perPage=-1${sort}`, props.token);
            }

            if(props.searchQuery === 'giftee') {
                //let filterGifteesString = parseInt(user.role) === 0 ? `,"boutique_id" : "${user.boutique_id}"` : '';
                let filterGifteesString = parseInt(user.role) === 0 ? `,"dior_locale_id" : "${user.dior_locale_id}"` : '';
                response = await dataProvider.get(`user?filter={"role":"2","q": "${string}"${filterGifteesString}${filterString}}&perPage=-1${sort}`, props.token);
            }

            if (response !== null) {
                if (props.searchQuery === 'wishlist') {
                    setTotal(response.total !== null ? response.total : '');
                    setTotalString(t('filterSort.wishlists')+' '+response.total)
                    setWishlists(response.data);
                }

                if (props.searchQuery === 'webWishlist') {
                    setTotal(response.total !== null ? response.total : '');
                    setTotalString(t('filterSort.wishlists')+' '+response.total)
                    setWebWishlists(response.data);
                }

                if (props.searchQuery === 'giftee') {
                    setTotal(response.total !== null ? response.total : '');
                    setTotalString(t('filterSort.giftees')+' '+response.total);
                    setGiftees(response.data);
                }

                if (props.searchQuery === 'products') {
                    setTotal(props.products.total !== null ? props.products.total : '');
                    setTotalString(t('filterSort.products')+' '+props.products.total);
                    //setProducts(response !== null ? response : null);
                }
            } else {
                console.log('error');
            }
        } else if(string.length === 0) {
            let response: any = '';
            if(props.searchQuery === 'products') {
                if(routeType == 'catalogue') {
                    props.setQuery(`?filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`)
                    //response = await dataProvider.get(`product?filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`, props.token);
                    setFiltersQueryString(`filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}", "main_category_id": ${parseInt(props.category)}${filterString}${languageParams}}&perPage=-1${sort}`);
                } else {
                    props.setQuery(`?filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`);
                    //response = await dataProvider.get(`product?filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`, props.token);
                    setFiltersQueryString(`filter={"dior_locale_id": "${wishlist.boutique.dior_locale_id}","wishlist_id": "${wishlist.id}"${filterString}${languageParams}}&perPage=-1`);
                }
            } else {
                if(props.searchQuery === 'wishlist' || props.searchQuery === 'webWishlist') {
                    let createdByFilter = '';
                    if (props.searchQuery === 'wishlist') {
                        createdByFilter = '[0,1]';
                    } else if (props.searchQuery === 'webWishlist') {
                        createdByFilter = '2';
                    }
                    //console.log(filterString, 'filterString in search');
                    const filterWishlistByBoutique = filterString !== '' ? filterString : '';
                    const filterByRole = parseInt(user.role) === 0 ? `,"wishlist_dior_locale_id" : ${user.dior_locale_id}` : '';
                    const filterWishlistString = props.searchQuery === 'webWishlist' || props.searchQuery === 'wishlist' ? `&filter={"created_by" : ${createdByFilter}, "status": 1 ${filterWishlistByBoutique}${filterByRole}}` : '';

                    response = await dataProvider.get(`${props.searchQuery !== 'webWishlist' ? props.searchQuery : 'wishlist'}?perPage=-1${filterWishlistString}${sort}`, props.token);
                } else {
                    const filterGifteesString = parseInt(user.role) === 0 ? `"role":2, "dior_locale_id" : ${user.dior_locale_id}${filterString}` : `"role":2 ${filterString}`;
                    response = await dataProvider.get(`user?perPage=-1&filter={${filterGifteesString}}${sort}`, props.token);
                }
            }

            if (response !== null) {
                //console.log(response);
                if (props.searchQuery === 'wishlist') {
                    setTotalString(t('filterSort.wishlists')+' '+response.total);
                    setTotal(response.total);
                    setWishlists(response.data);
                }

                if (props.searchQuery === 'webWishlist') {
                    setTotalString(t('filterSort.wishlists')+' '+response.total);
                    setTotal(response.total);
                    setWebWishlists(response.data);
                }

                if (props.searchQuery === 'giftee') {
                    setTotalString(t('filterSort.giftees')+' '+response.total);
                    setTotal(response.total);
                    setGiftees(response.data);
                }

                if (props.searchQuery === 'products') {
                    //setTotal('');
                    //setTotalString('');
                    //if(routeType == 'catalogue') {
                    //setProducts(response);
                    setTotal(props.products.total !== null ? props.products.total : '');
                    setTotalString('Products '+props.products.total);
                }
            }
        }
    }

    const renderElements = () => {
        if(props.searchQuery === 'wishlist') {
            if (wishlists === null) return null;
            return (
                <WishlistsPreview wishlists={wishlists} isLoading={isLoading} />
            );
        }

        if(props.searchQuery === 'webWishlist') {
            if (webWishlists === null) return null;
            return (
                <WishlistsPreview wishlists={webWishlists} isLoading={isLoading} />
            );
        }

        if(props.searchQuery === 'giftee') {
            if (giftees === null) return null;
            return (
                <GifteesPreview giftees={giftees} isLoading={isLoading}/>
            );
        }

        if (props.searchQuery === 'products') {
            //console.log(props.wishlist, 'wishlist in searchbar component')
            //console.log(products, 'products in searchbar component')
            //console.log(props.routeType, 'routeType in searchbar component')
            if(products === null || wishlist === null) return null;
            return (
                <ProductsList
                    category={routeType === 'catalogue' ? categoryId : null}
                    wishlist={wishlist}
                    products={products}
                    routeType={routeType}
                    isLoading={isLoading}
                    filtersQueryString={filtersQueryString}
                    sortQueryString={sortBy}
                    setImagesLoaded={props.setImagesLoaded}
                />
            );
        }
    }

    const { t, i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [pageType, setPageType] = useState<string>(''); // ['catalogue', 'wishlist']
    const filterSortModal = () => {
        let buttonCloseText: any = '';
        if(props.searchQuery === 'products') {
            buttonCloseText = t('filterSort.products');
        } else if(props.searchQuery === 'wishlist') {
            buttonCloseText = t('filterSort.wishlists');
        } else if (props.searchQuery === 'webWishlist') {
            buttonCloseText = t('filterSort.wishlists');
        } else if (props.searchQuery === 'giftee') {
            buttonCloseText = t('filterSort.giftees');
        }


        let resetFilters: any = '';
        if(activeSizeFilter.length > 0 || activeCategoryFilter.length > 0 || activeColorFilter.length > 0 || activeGenderFilter.length > 0) {
            resetFilters = <div className="ResetFiltersContainer">
                <span onClick={() => {
                    search(searchString, sortBy, '');
                    setActiveSizeFilter([]);
                    setActiveCategoryFilter([]);
                    setActiveColorFilter([]);
                    setActiveGenderFilter([]);
                    setTotal(products.total !== null ? products.total : '');
                    setIsModalOpen(false)}}>{t('resetFilters')}</span>
            </div>
        }

        return (
            <Modal
                className="right"
                show={isModalOpen}
                onHide={() => {setIsModalOpen(false)}}
                dialogClassName="fgift-custom-dialog filter-sort-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header style={{
                    borderBottom: '1px solid red'
                }} className="border-0" closeButton>
                    <Modal.Title>{modalType === 'sort' ? t('filterSort.sort') : t('filterSort.filter')}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display:'block', maxHeight: '300px'}}>
                    {/*<Filters active={filtersActive} />*/}
                    {modalType === 'sort' ? Sort() : Filter()}
                    {resetFilters}
                </Modal.Body>
                <div className="ButtonContainer FilterButton" style={{width: '100%', padding: '0px 20px'}}>
                    <button className="Fgift-button" onClick={() => {setIsModalOpen(false)}}>
                        {t('filterSort.see')} {total !== '' && products !== null && products.data !== null ? `(${total}) ` : ''} {buttonCloseText}
                    </button>
                </div>
            </Modal>
        );
    }

    const handleSort = (item: any) => {
        let sortString = '';
        if(item === 'Creation date') {
            sortString = '["created_at","DESC"]';
            setActiveSort('Creation date');
        }

        if(item === 'Name') {
            sortString = '["name","ASC"]';
            setActiveSort('A-Z');
        }

        if(item === 'Price - High first') {
            sortString = '["price_value","DESC"]';
            setActiveSort('Price - High first');
        }

        if(item === 'Price - Low first') {
            sortString = '["price_value","ASC"]';
            setActiveSort('Price - Low first');
        }

        search(searchString, sortString, filterBy);
    }

    const handleFilter = (type: any, item: any) => {
        let filterCategoryString = '';
        let filterSizeString = '';
        let filterColorString = '';
        let filterGenderString = '';
        let filterString = '';

        if(type === 'category') {
            if(activeCategoryFilter.includes(parseInt(item.id))) {
                activeCategoryFilter.splice(activeCategoryFilter.indexOf(parseInt(item.id)), 1);
            } else {
                activeCategoryFilter.push(parseInt(item.id))
            }
            setActiveCategoryFilter(activeCategoryFilter);
        }

        if(type === 'size') {
            let itemString = `"${item}"`;
            if(activeSizeFilter.includes(itemString)) {
                activeSizeFilter.splice(activeSizeFilter.indexOf(itemString), 1);
            } else {
                activeSizeFilter.push(itemString);
            }
            setActiveSizeFilter(activeSizeFilter);
        }

        if(type === 'color') {
            let itemString = `"${item}"`;
            if(activeColorFilter.includes(itemString)) {
                activeColorFilter.splice(activeColorFilter.indexOf(itemString), 1);
            } else {
                activeColorFilter.push(itemString);
            }
            setActiveColorFilter(activeColorFilter);
        }

        if(type === 'gender') {
            let itemString = `"${item}"`;
            if(activeGenderFilter.includes(itemString)) {
                activeGenderFilter.splice(activeGenderFilter.indexOf(itemString), 1);
            } else {
                activeGenderFilter.push(itemString);
            }
            setActiveGenderFilter(activeGenderFilter);
        }

        let boutiqueIdForFilter: any = null;
        if (type === 'boutique' && props.searchQuery !== 'products') {
            if(activeBoutiqueFilter.length === 0) {
                setActiveBoutiqueFilter(item.id);
                props.setParentActiveBoutiqueId(item.id);
                boutiqueIdForFilter = item.id;
            } else {
                if(activeBoutiqueFilter === item.id) {
                    boutiqueIdForFilter = null;
                    setActiveBoutiqueFilter('');
                    props.setParentActiveBoutiqueId(null);
                } else {
                    boutiqueIdForFilter = item.id;
                    setActiveBoutiqueFilter(item.id);
                    props.setParentActiveBoutiqueId(item.id);
                }
            }
        }

        let localeFilter: any = null;
        if(type === 'locale' && props.searchQuery !== 'products') {
            if(activeLocaleFilter === null) {
                localeFilter = item.id;
                setActiveLocaleFilter(item.id);
                props.setParentLocaleId(item.id);
            } else {
                if(activeLocaleFilter === item.id) {
                    setActiveLocaleFilter(null);
                    props.setParentLocaleId(null);
                } else {
                    localeFilter = item.id;
                    setActiveLocaleFilter(item.id);
                    props.setParentLocaleId(item.id);
                }
            }
        }

        if(activeCategoryFilter.length > 0) {
            filterCategoryString = filterCategoryString+`,"sub_category_id" : [${activeCategoryFilter}]`;
        }

        if(activeSizeFilter.length > 0) {
            filterSizeString = filterSizeString+`,"size_label" : [${activeSizeFilter}]`;
        }

        if(activeColorFilter.length > 0) {
            filterColorString = filterColorString+`,"color" : [${activeColorFilter}]`;
        }

        if(activeGenderFilter.length > 0) {
            filterGenderString = filterGenderString+`,"gender" : [${activeGenderFilter}]`;
        }

        filterString = filterCategoryString+filterSizeString+filterColorString+filterGenderString;


        /*if(boutiqueIdForFilter !== null) {
            filterString = filterString+`,"boutique_id" : "${boutiqueIdForFilter}"`;
            props.setParentBoutiqueFilter(filterString);
            setActiveBoutiqueFilter(boutiqueIdForFilter);
            props.setParentActiveBoutiqueId(boutiqueIdForFilter);
        } else {
            props.setParentBoutiqueFilter(null);
            props.setParentActiveBoutiqueId(null);
            setActiveBoutiqueFilter('');
        }*/

        if(localeFilter !== null) {
            //console.log(props.searchQuery, 'props.searchQuery');
            if(props.searchQuery === 'giftee') {
                filterString = `,"dior_locale_id" : "${localeFilter}"`;
            } else {
                filterString = `,"wishlist_dior_locale_id" : "${localeFilter}"`;
            }
            setActiveLocaleFilter(localeFilter);
            props.setParentLocaleId(localeFilter);
        }

        search(searchString, sortBy, filterString);
    }

    const Sort =() => {
        if(!isSortActive) {
            return null;
        }
        let icon: any = '';
        return <div className="SortContainer">
            <div className="SortBy">
                {sortItems.map((item: any, i: any) => {

                    let style = {fontWeight: 'normal'};
                    if(activeSort === item || (item === 'Name' && activeSort === 'A-Z')) {
                        style = {fontWeight: 'bold'};
                        icon = <CheckIcon style={{color: '#000', fontSize: '20px'}}/>
                    } else {
                        icon = '';
                    }

                    return (
                        <div key={i} className="Sort">
                            <p style={style} onClick={() => {handleSort(item)}}>{icon} {getSortTranslation(item)}</p>
                        </div>
                    )
                })
                }
            </div>
        </div>;
    }

    const Filter = () => {
        //console.log(filterSizeItems, 'filterSizeItems in searchbar component');
        if(!isFilterActive) {
            return null;
        }
        let filtersSize: any = '';
        if(filterSizeItems !== null && filterSizeItems.length > 1) {
            filtersSize = <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="filters-panel1a-content"
                    id="filters-panel1a-header">
                    <p>{t('filterSort.size')}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="Filters">
                        {filterSizeItems.map((item: any, i: any) => {
                            let style = {fontWeight: 'normal'};
                            let itemString = `"${item}"`;
                            if(activeSizeFilter.length > 0 && activeSizeFilter.includes(itemString)) {
                                style = {fontWeight: 'bold'};
                            }
                            return (
                                <div key={i} onClick={() => {handleFilter('size', item)}} className="Filter">
                                    <p style={style}>{item}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>;
        }

        let filtersCategory: any = '';
        if(filterCategoryItems !== null && filterCategoryItems.length > 1) {
            filtersCategory = <Accordion>
                <AccordionSummary
                    style={{marginTop:'0x'}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="filters-panel1a-content"
                    id="filters-panel1a-header">
                    <p>{t('filterSort.category')}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="Filters">
                        {filterCategoryItems.map((item: any) => {
                            let styleCat: any = {fontWeight: 'normal', textTransform: "capitalize"};
                            if(activeCategoryFilter.length > 0 && activeCategoryFilter.includes(item.id)) {
                                styleCat = {fontWeight: 'bold', textTransform: "capitalize"};
                            }
                            return (
                                <div key={item.id} onClick={() => {handleFilter('category', item)}} className="Filter">
                                    <p style={styleCat}>{item.name}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>;
        }

        let filtersColor: any = '';
        if(filterColorItems !== null && filterColorItems.length > 1) {
            filtersColor = <Accordion>
                <AccordionSummary
                    style={{marginTop:'0px'}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="filters-panel1a-content"
                    id="filters-panel1a-header">
                    <p>{t('filterSort.color')}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="Filters">
                        {filterColorItems.map((item: any) => {
                            let styleColor: any = {fontWeight: 'normal', textTransform: "capitalize"};
                            let itemString = `"${item}"`;
                            if(activeColorFilter.length > 0 && activeColorFilter.includes(itemString)) {
                                styleColor = {fontWeight: 'bold', textTransform: "capitalize"};
                            }
                            return (
                                <div key={item} onClick={() => {handleFilter('color', item)}} className="Filter">
                                    <p style={styleColor}>{t(`colorItems.${item}`)}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>;
        }

        let filtersGender: any = '';
        if(filterGenderItems !== null && filterGenderItems.length > 0) {
            filtersGender = <Accordion>
                <AccordionSummary
                    style={{marginTop:'0px'}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="filters-panel1a-content"
                    id="filters-panel1a-header">
                    <p>{t('genderItems.title')}</p>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="Filters">
                        {filterGenderItems.map((item: any) => {
                            let styleGender: any = {fontWeight: 'normal', textTransform: "capitalize"};
                            let itemString = `"${item}"`;
                            if(activeGenderFilter.length > 0 && activeGenderFilter.includes(itemString)) {
                                styleGender = {fontWeight: 'bold', textTransform: "capitalize"};
                            }
                            return (
                                <div key={item} onClick={() => {handleFilter('gender', item)}} className="Filter">
                                    <p style={styleGender}>{t(`genderItems.${item}`)}</p>
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>;
        }

        let filtersBoutique: any = '';
        //console.log(filterBoutiqueItems, 'filterBoutiqueItems');
        if(filterBoutiqueItems !== null && props.searchQuery !== 'products') {
            //console.log(filterBoutiqueItems, 'filterBoutiqueItems2');
            filtersBoutique =
                    <div className="Filters">
                        {filterBoutiqueItems.data.map((item: any) => {
                            //console.log(item, 'item in filterBoutiqueItems')
                            let styleBoutique: any = {fontWeight: 'normal', textTransform: "capitalize"};
                            if(activeBoutiqueFilter.length > 0 && activeBoutiqueFilter.includes(item.id)) {
                                styleBoutique = {fontWeight: 'bold', textTransform: "capitalize"};
                            }
                            return (
                                <div key={item.id} onClick={() => {handleFilter('boutique', item)}} className="Filter">
                                    <p style={styleBoutique}>{item.name}</p>
                                </div>
                            )
                        })}
                    </div>;
        }

        let filtersLocale: any = '';
        if(filterLocaleItems.length > 1 && props.searchQuery !== 'products') {
            filtersLocale = <div className="Filters">
                        {filterLocaleItems.map((item: any) => {
                            let styleLocale: any = {fontWeight: 'normal', textTransform: "capitalize"};
                            if(props.activeLocaleId !== null && props.activeLocaleId === item.id) {
                                if(activeLocaleFilter !== null && activeLocaleFilter === item.id) {
                                    styleLocale = {fontWeight: 'bold', textTransform: "capitalize"};
                                }
                            }
                            return (
                                <div key={item.id} onClick={() => {handleFilter('locale', item)}} className="Filter">
                                    <p style={styleLocale}>{item.name}</p>
                                </div>
                            )
                        })}
                    </div>;
        }

        return <div className="FiltersContainer">
            {filtersSize}
            {filtersCategory}
            {filtersColor}
            {filtersGender}
            {filtersBoutique}
            {filtersLocale}
        </div>;
    }

    const openFilterSortModal = () => {
        if(isSortActive && !isFilterActive) {
            return (<div className="OpenFiltersContainer">
                <span onClick={() => {setModalType('sort'); setIsModalOpen(true)}}>{t('filterSort.sort')} {getSortTranslation(activeSort)}</span>
            </div>)
        }

        if(isFilterActive && !isSortActive) {
            let filtersTotal = activeSizeFilter.length + activeCategoryFilter.length + activeColorFilter.length + activeGenderFilter.length;
            let filtersLabel = filtersTotal > 0 ? ' ('+filtersTotal+')' : ' (0)';
            return (<div className="OpenFiltersContainer">
                <span onClick={() => {setModalType('filter'); setIsModalOpen(true)}}>{t('filterSort.filter')}{filtersLabel}</span>
            </div>)
        }

        if(isFilterActive && isSortActive) {
            let filtersTotal = activeSizeFilter.length + activeCategoryFilter.length + activeColorFilter.length + activeGenderFilter.length;
            if(props.searchQuery !== 'products') {
                //filtersTotal = activeBoutiqueFilter.length > 0 ? 1 : 0;
                filtersTotal = props.activeLocaleId !== null ? 1 : 0;
            }
            let filtersLabel = filtersTotal > 0 ? ' ('+filtersTotal+')' : ' (0)';
            return (<div className="OpenFiltersContainer">
                <span onClick={() => {setModalType('sort'); setIsModalOpen(true)}}>{t('filterSort.sort')} {getSortTranslation(activeSort)}</span>
                <span onClick={() => {setModalType('filter'); setIsModalOpen(true)}}>{t('filterSort.filter')}{filtersLabel}</span>
            </div>)
        }

    }

    const getSortTranslation = (item: any) => {
        switch (item) {
            case 'Creation date':
                return t('filterSort.creationDate');
            case 'Name':
                return t('filterSort.name');
            case 'Price - High first':
                return t('filterSort.priceHigh');
            case 'Price - Low first':
                return t('filterSort.priceLow');
            default:
                return item;
        }
    }

    const openSortModal = () => {
        return (<div className="OpenSortContainer">
            <span>Sort</span>
        </div>)
    }

    return (
    <div className="SearchBarContainer">
        <form className="MobileSearchBar d-block d-lg-none"
              onSubmit={(e) => {
                  e.preventDefault(); // Previene il refresh della pagina
                  search(searchString, sortBy, filterBy);
              }}
        >
            <TextField
                id="search-bar"
                style={{width: '100%'}}
                onInput={(e: any) => {
                    search(e.target.value, sortBy, filterBy);
                }}
                onSubmit={(e: any) => {
                    e.preventDefault();
                    search(searchString, sortBy, filterBy);
                }}
                label={searchString !== '' ? '' : searchLabel}
                value={searchString}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton type="submit"  aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
        <div className="SearchBarBottom">
            <span>{totalString}</span>
            <div className="d-flex justify-content-center align-items-center">
                <form className="DesktopSearchBar d-none d-lg-block"
                      onSubmit={(e) => {
                          e.preventDefault(); // Previene il refresh della pagina
                          search(searchString, sortBy, filterBy);
                      }}
                >
                    <TextField
                        id="search-bar"
                        style={{width: '100%'}}
                        onInput={(e: any) => {
                            search(e.target.value, sortBy, filterBy);
                        }}
                        label={searchString !== '' ? '' : t('searchShort')}
                        value={searchString}
                        variant="standard"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit" aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                    />
                </form>
                {openFilterSortModal()}
            </div>
        </div>
        {filterSortModal()}
        {renderElements()}
    </div>
    )
};

export default SearchBar;