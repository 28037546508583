import React, {useEffect, useState} from "react";
import Header from "../Header";
import {
    Button,
    TextField,
    Typography,
    Alert,
    IconButton,
    Collapse, InputAdornment, Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

import {useNavigate} from "react-router";
import { useTranslation, withTranslation } from 'react-i18next';

import './CreateMessage.css';
import {useSearchParams} from "react-router-dom";

import {checkPathName, frontendBaseURL, mainRolesRoutes} from "../../global";

import './GifterDetails.css';

import dataProvider from "../../provider/api-interface";
import jwt from "jwt-decode";


function GifterDetails() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);

    const [searchParams, setSearchParams] = useSearchParams();

    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState(true);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success">("error");
    const [alertText, setAlertText] = useState<any>(t('messageEmpty'));

    const [product, setProduct] = useState<any>(null);
    const [wishlist, setWishlist] = useState<any>(null);

    const wishlistProductId = searchParams.get('id');
    const wishlistId = searchParams.get('wishlistId');

    if(wishlistProductId === undefined || wishlistProductId === null || wishlistProductId === '') {
        navigate(`/${pathName}/404`);
    }

    const jwtToken: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    //Use anywhere
    if(!checkPathName(pathName, jwtToken)) {
        navigate('/404');
    }

    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere

    const getWishlist = () => {
        //console.log("Product code: " + productCode)
        dataProvider.getWishlistByListCode(wishlistId!, jwtToken).then((response) => {
            if(response !== null) {
                //console.log("Wishlist: " + JSON.stringify(response));
                setWishlist(response);
                response.products.forEach((product: any) => {
                    if(product.wishlist_x_product_id === wishlistProductId) {
                        setProduct(product);
                    }
                });
            }
        }).catch((error) => {
            console.log("Error getting wishlist: " + error);
        });
    }

    React.useEffect(() => {
        getWishlist();
    }, []);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSurname(event.target.value);
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setIsEmailValid(validateEmail(event.target.value));
    };

    const validateEmail = (email: string) => {
        // Basic email validation using a regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const setItemPurchased = (gifterId: string, userToken: any) => {
        let purchased_id = user.id;
        dataProvider.setItemPurchased(wishlist.id ,gifterId, purchased_id, wishlistProductId, jwtToken).then((response) => {
            if(response !== null) {
                setAlertOpen(true);
                setAlertText(t('purchaseSuccess'));
                setAlertType("success");
                setTimeout(() => {
                    navigate(`/${pathName}/product?id=${product.product_code}&wishlistId=${wishlist.list_code}`);
                }, 3000);
            } else {
                console.log("Error purchasing item");
                setAlertOpen(true);
                setAlertText(t('purchaseError'));
                setAlertType("error");
                dataProvider.delete('user', gifterId, jwtToken).then((response) => {
                    if(response !== null) {
                        console.log("Gifter deleted: " + JSON.stringify(response));
                    } else {
                        console.log("Error deleting gifter");
                    }
                }).catch((error) => {
                    console.log("Error deleting gifter: " + error);
                })
            }
        }).catch((error) => {
            console.log("Error purchasing item: " + error);
            setAlertOpen(true);
            setAlertText(t('purchaseError'));
            setAlertType("error");
            dataProvider.delete('user', gifterId, jwtToken).then((response) => {
                if(response !== null) {
                    console.log("Gifter deleted: " + JSON.stringify(response));
                } else {
                    console.log("Error deleting gifter");
                }
            }).catch((error) => {
                console.log("Error deleting gifter: " + error);
            });
        });
    }

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if(name === "" || surname === "" || email === "") {
            setAlertOpen(true);
            setAlertText(t('requiredItemsEmpty'));
            setAlertType("error");
        }else if(!isEmailValid){
            setAlertOpen(true);
            setAlertText(t('emptyEmail'));
            setAlertType("error");
        }else{
            setAlertOpen(true);
            setAlertText(t('accountCreated'));
            setAlertType("success");
        }

        let data = {
            "name": name,
            "surname": surname,
            "email": email,
            "role": 3,
            "boutique_id": wishlist.boutique.id,
            "dior_locale_id": wishlist.dior_locale_id
        }

        console.log(data,'here');

        dataProvider.create('user', data, jwtToken).then((response) => {
            if(response.data !== null) {
                console.log("User created: " + JSON.stringify(response));
                setItemPurchased(response.data.id, response.data.token_confirm)
            } else {
                console.log("Error creating user");
                setAlertText(t('accountNotCreated'));
                if(response.status === 409) {
                    setAlertText(t('emailAlreadyExists'));
                }
                setAlertOpen(true);
                setAlertType("error");
            }
        }).catch((error) => {
            console.log("Error creating user: " + error);
        });
    }

    //console.log("Product: " + JSON.stringify(product));
    let productName = product !== null ? product?.title : "";
    return (
        <div>
            <Header link={`/${pathName}/product?id=${product?.product_code}&wishlistId=${wishlist?.list_code}`} textLink={t('backTo') + ' ' + productName} />
            <div className="row no-gutter">
                <div className="col-12 col-lg-3 d-md-block d-none">
                    <div className="FormTitle d-none d-lg-block p-4"><h4>{t('gifterDetailsTitle')}</h4></div>
                </div>
                <div className="col-12 col-lg-6">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert severity={alertType}
                               onClose={() => setAlertOpen(false)}
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setAlertOpen(false);
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit" />
                                   </IconButton>
                               }
                        >
                            {alertText}
                        </Alert>
                    </Collapse>
                    <form className="LoginForm" onSubmit={handleFormSubmit}>
                <div className="FormTitle GifterFormTitle d-block d-lg-none gap-3"><h4>{t('gifterDetailsTitle')}</h4></div>
                <div className="NameContainer">
                    <TextField
                        label={t("nameLabel")}
                        value={name}
                        required={true}
                        onChange={handleNameChange}
                        variant="standard"
                    />
                </div>
                <div className="SurnameContainer">
                    <TextField
                        label={t("surnameLabel")}
                        value={surname}
                        required={true}
                        onChange={handleSurnameChange}
                        variant="standard"
                    />
                </div>
                <div className="EmailContainer">
                    <TextField
                        label={t("emailLabel")}
                        value={email}
                        required={true}
                        onChange={handleEmailChange}
                        error={!isEmailValid}
                        helperText={!isEmailValid && t('notValidMailHelper')}
                        variant="standard"
                    />
                </div>
                <div className="ButtonContainer position-lg-relative">
                    <button type="submit" style={{width: 'calc(100% - 40px)'}} className="Fgift-button">
                        {t('sendEmailButton')}
                    </button>
                </div>
            </form>
                </div>
                <div className="col-12 col-lg-3 d-md-block d-none">
                </div>
            </div>
        </div>)
}

export default withTranslation()(GifterDetails);