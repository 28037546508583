import React, {useEffect, useState} from 'react';
//import { login } from '../../services/authService';

import { useLocation, useNavigate } from "react-router";
import {useSearchParams} from "react-router-dom";

import FooterDior from "./FooterDior";

import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    Button,
    TextField,
    Modal,
    Typography,
    Alert,
    AlertTitle,
    Collapse,
    IconButton,
    InputAdornment, Checkbox, Select, MenuItem
} from '@mui/material';
import Header from '../Header';
import './PublicRegistration.css';
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {Visibility} from "@mui/icons-material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {Link} from "react-router-dom";
import {validateEmail, validatePassword} from "../../services/CheckUtils";
import dataProvider from "../../provider/api-interface";
import {changeLanguage} from "i18next";

import Background from "../../media/images/FreeRegistrationBackground.jpeg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {defaultCountries, FlagEmoji, parseCountry, usePhoneInput} from "react-international-phone";
import {MUIPhoneProps} from "./CreateGiftee";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {isValidPhoneNumber} from "react-phone-number-input";
import jwt from "jwt-decode";
import {mainRolesRoutes, rolesCodes} from "../../global";

const MuiPhone: React.FC<MUIPhoneProps> = ({
                                               value,
                                               onChange,
                                               defaultCountryString,
                                               ...restProps
                                           }) => {
    const { t, i18n } = useTranslation();
    const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
        usePhoneInput({
            defaultCountry: defaultCountryString,
            value,
            countries: defaultCountries,
            onChange: (data) => {
                setReadOnly(true);
                onChange(data.phone);
                //console.log(data);
                setReadOnly(false)
            },
        });
    const [readOnly, setReadOnly] = useState<any>(true);

    return (
        <TextField
            variant="standard"
            label={t("phoneLabel")}
            color="primary"
            placeholder="Phone number"
            value={phone}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            InputProps={{
                readOnly: readOnly,
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: '2px', marginLeft: '-8px' }}
                    >
                        <Select
                            MenuProps={{
                                style: {
                                    height: '300px',
                                    width: '360px',
                                    top: '10px',
                                    left: '-34px',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                            }}
                            sx={{
                                width: 'max-content',
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: 'none',
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: 'block',
                                    },
                                },
                                // Update default spacing
                                '.MuiSelect-select': {
                                    padding: '8px',
                                    paddingRight: '24px !important',
                                },
                                svg: {
                                    right: 0,
                                },
                            }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagEmoji iso2={value} style={{ display: 'flex' }} />
                            )}
                        >
                            {defaultCountries.map((c) => {
                                const country = parseCountry(c);
                                return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagEmoji
                                            iso2={country.iso2}
                                            style={{ marginRight: '8px' }}
                                        />
                                        <Typography marginRight="8px">{country.name}</Typography>
                                        <Typography color="gray">+{country.dialCode}</Typography>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </InputAdornment>
                ),
            }}
            {...restProps}
        />
    );
};
function GetStarted() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const date = new Date();

    const [defaultCountry, setDefaultCountry] = useState<any>(searchParams.get('locale') !== null && searchParams.get('locale')?.includes('_') ? searchParams.get('locale')?.split('_')[1] : 'fr_fr');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

    const [boutiqueLocale, setBoutiqueLocale] = useState<any>(searchParams.get('locale') !== null ? searchParams.get('locale') : 'fr_fr');


    /*const [role, setRole] = useState<string>(props.role);
    const [routeRedirect, setRouteRedirect] = useState<string>(props.routeRedirect);*/
    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [civility, setCivility] = useState<string>("");
    const [phone, setPhone] = useState<any>("");

    const [eventName, setEventName] = useState<string>("");
    const [eventDate, setEventDate] = useState<number>(date.setDate(date.getDate() + 30));
    const [closingDate, setClosingDate] = useState<number>(date.setDate(date.getDate() + 180));

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<"error" | "success" | "warning">("error");
    const [alertText, setAlertText] = useState<any>(t('wrongCredentials'));

    const location = useLocation();

    const isValidUIID = (uuid: string) => {
        if(uuid === null || uuid === undefined) {
            return false;
        }

        if(uuid.length !== 36) {
            return false;
        }

        const uuidV4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

        return uuidV4Regex.test(uuid);
    }

    const pathId = window.location.pathname.split('/')[2];
    const userId = atob(pathId);
    if(!isValidUIID(userId)) {
        navigate('/error');
    }


    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
    }
    const validatePhoneNumber = (phone: string) => {
        //console.log('+'+phone)
        //console.log(isValidPhoneNumber('+'+phone))
        return isValidPhoneNumber(phone);
    }

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setButtonDisabled(true);
        //PERSONAL INFO
        if (civility === "") {
            setAlertText(t('emptyCivility'));
            setAlertType("error");
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if(name === "") {
            setAlertText(t('emptyName'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if(surname === "") {
            setAlertText(t('emptySurname'));
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        if(phone === "") {
            setAlertText(t('emptyPhone'));
            setAlertType("warning");
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        //VALIDATE MAIL AND PHONE
        if (!validatePhoneNumber(phone)) {
            //console.log(phone);
            setAlertText(t('phoneNotValid'));
            setAlertType("error");
            setAlertOpen(true);
            setButtonDisabled(false);
            autoClose();
            return;
        }

        //GIFT LIST INFO
        if(eventName === "") {
            setAlertText(t('emptyEventName'));
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        if(!checkDates(eventDate, closingDate)) {
            setAlertText(t('closingDateError'));
            setAlertType("warning");
            setAlertOpen(true);
            autoClose();
            setButtonDisabled(false);
            return;
        }

        let formattedEventDate = dayjs(eventDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');
        let formattedClosingDate = dayjs(closingDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');

        let registrationObject = {
            name: name,
            surname: surname,
            civility: civility,
            phone_number: phone,
            language_locale_id: i18n.language === 'fr' ? 1 : 2,
            id_dior_star: null,
            event_name: eventName,
            start_date: formattedEventDate,
            end_date: formattedClosingDate,
        }

        console.log(registrationObject, 'record');

        await dataProvider.getStarted(userId ,registrationObject).then((response: any) => {
            if(response !== null) {
                if(response.status === 200) {
                    console.log(response, 'response');
                    const token: string = response.data.token;
                    console.log(token, 'token');
                    localStorage.setItem('token', token);

                    let user: any = jwt(token);
                    let roleCode: number = parseInt(user.role);
                    let role = rolesCodes[roleCode];
                    const language = user.locale_id !== 1 ? 'en_gb' : 'fr_fr';
                    setLanguage(language);
                    dataProvider.getWishlistByGifteeId(user.id, token).then((wishlist: any) => {
                        if (wishlist !== null && wishlist.data !== null) {
                            //console.log(wishlist, 'wishlist')
                            //console.log(`/${mainRolesRoutes[role]}/registry/${wishlist[0].id}`)
                            navigate(`/${mainRolesRoutes[role]}/registry/${wishlist.data[0].list_code}`);
                        } else {
                            setAlertText(t('registrationError'));
                            setAlertOpen(true);
                            setAlertType("error");
                            setButtonDisabled(false);
                            autoClose();
                        }
                    });

                } else {
                    setAlertText(t('registrationError'));
                    if(response.status === 409) {
                        setAlertText(t('emailAlreadyExists'));
                    }
                    setAlertOpen(true);
                    setAlertType("error");
                    setButtonDisabled(false);
                    autoClose();
                }
            }
        });

    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const autoClose = () => {
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000);
    };

    const checkDates = (eventDate: any, closingDate: any): boolean => {
        let eventDateNumber = parseInt(eventDate);
        let closingDateNumber = parseInt(closingDate);
        if(eventDateNumber === 0 || closingDateNumber === 0) {
            return false;
        }

        if(eventDateNumber > closingDateNumber) {
            return false;
        }

        return true;
    }

    const titlesEN = [
        'Mr',
        'Mrs',
        'Miss',
        'Ms'
    ];

    const titlesFR = [
        'M.',
        'Mme',
        'Mlle'
    ];

    const renderCivility = () => {
        let titles = titlesEN;

        if(i18n.language === 'fr') {
            titles = titlesFR;
        }

        return  <FormControl fullWidth className="CivilityContainer">
            <InputLabel id="civility-select-label">{t('titleClientNameLabel')}</InputLabel>
            <Select
                MenuProps={{autoFocus: false}}
                labelId="civility-select-label"
                id="civility-select"
                value={civility}
                onChange={(e) => setCivility(e.target.value)}
                variant="standard"
                required={false}
                style={{width: '100%'}}
                IconComponent={ExpandMoreIcon}
            >
                {titles?.map((title: any, i: any) => (
                    <MenuItem
                        value={title}
                        selected={civility === title}
                        key={i}
                        style={{fontSize: '13px', fontFamily: 'Hellix'}}
                    >
                        {title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>


    }


    return (
        <div>
            <Header link={`/${boutiqueLocale}/baby-gift-list`} textLink={t('backTo')} />
            <div className="row no-gutter">
                <div className="col-12 col-lg-6 PublicRegistrationTitle" style={{minHeight: '21vh', background: `url(${Background})`, backgroundPosition: '20% 20%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                    <h3 className="d-block d-lg-none text-uppercase">{t('loginSignupPage.getStartedTitle')}</h3>
                </div>
                <div className="col-12 col-lg-6 d-lg-flex align-items-lg-center">
                    <Collapse className="FgiftCustomCollapse" in={alertOpen}>
                        <Alert severity={alertType}
                               onClose={() => setAlertOpen(false)}
                               action={
                                   <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                           setAlertOpen(false);
                                       }}
                                   >
                                       <CloseIcon fontSize="inherit" />
                                   </IconButton>
                               }
                        >
                            {alertText}
                        </Alert>
                    </Collapse>
                    <div className="row w-100" style={{height: "100vh"}}>
                        <div className="col-12 col-lg-2 d-none d-lg-block"></div>
                        <div className="col-12 col-lg-8">
                            <h5 className="d-none d-lg-block px-3 mt-5 mb-3" style={{fontWeight: '700', fontFamily: 'Atacama VAR'}}>{t('loginSignupPage.getStartedTitle')}</h5>
                            <form className="LoginForm PublicRegistrationForm" onSubmit={handleFormSubmit}>
                                <div style={{marginBottom: '30px'}} className="FormContainer">
                                    <div className="FormTitle"><h4>{t("publicRegister.personalInformation")}</h4></div>
                                    {renderCivility()}
                                    <TextField
                                        label={t("nameLabel")}
                                        value={name}
                                        onChange={(event) => {
                                            setName(event.target.value);
                                        }}
                                        required={true}
                                        variant="standard"
                                    />
                                    <TextField
                                        label={t("surnameLabel")}
                                        value={surname}
                                        onChange={(event) => {
                                            setSurname(event.target.value);
                                        }
                                        }
                                        required={true}
                                        variant="standard"
                                    />
                                    <MuiPhone value={phone} onChange={(event) => {
                                        setPhone(event);
                                        //console.log(event, 'phone');
                                    }}
                                              defaultCountryString={defaultCountry}
                                    ></MuiPhone>
                                </div>
                                <div className="FormContainer mt-5 mt-lg-0">
                                    <div className="FormTitle"><h4>{t("publicRegister.giftListDetails")}</h4></div>
                                    <TextField
                                        label={t('publicRegister.giftListName')}
                                        value={eventName}
                                        onChange={(event) => {
                                            setEventName(event.target.value);
                                        }}
                                        required={true}
                                        variant="standard"
                                    />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('eventDateLabel')}
                                            value={dayjs(eventDate)}
                                            onChange={(newValue) => {
                                                let datesToCheck = (newValue === null) ? 0 : dayjs(newValue).valueOf();
                                                setEventDate(datesToCheck)
                                                if (!checkDates(datesToCheck, closingDate)) {
                                                    setAlertOpen(true);
                                                    setAlertText(t('closingDateError'))
                                                    setAlertType("warning");
                                                    autoClose();
                                                }
                                            }}
                                            format={'DD/MM/YYYY'}
                                            slotProps={{textField: {variant: 'standard', helperText: t('loginSignupPage.startDateHelper')}}}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('closingDateLabel')}
                                            value={dayjs(closingDate)}
                                            onChange={(newValue) => {
                                                let datesToCheck = (newValue === null) ? 0 : dayjs(newValue).valueOf();
                                                setClosingDate(datesToCheck)
                                                if (!checkDates(eventDate, datesToCheck)) {
                                                    setAlertOpen(true);
                                                    setAlertText(t('closingDateError'))
                                                    setAlertType("warning")
                                                    autoClose();
                                                }
                                            }}
                                            format={'DD/MM/YYYY'}
                                            slotProps={{textField: {variant: 'standard', helperText: t('loginSignupPage.endDateHelper')}}}
                                        />
                                    </LocalizationProvider>
                                </div>
                                <div className="ButtonContainer" style={{position: 'relative'}}>
                                    <button disabled={buttonDisabled} id="signup-confirm-button" type="submit" className="Fgift-button">
                                        {t('publicRegister.submit')}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-lg-2 d-none d-lg-block"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(GetStarted);
