import React, {useEffect} from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

import {Link, useSearchParams} from 'react-router-dom';
import {useNavigate} from "react-router";

import './FooterDior.css';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import jwt from "jwt-decode";

interface FooterProps {
    showDisclaimer?: boolean;
    locale?: string;
    hideLanguageSelect?: boolean;
}


function FooterDior({showDisclaimer = true, locale= 'fr_fr', hideLanguageSelect = true}: FooterProps) {
    const { t, i18n } = useTranslation();
    let initialLanguage = i18n.language !== 'fr' && i18n.language !== 'en' ? 'en' : i18n.language;


    const token = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const user: any = token === null ? null : jwt(token);

    if(sessionStorage.getItem('locale') !== null) {
        initialLanguage = sessionStorage.getItem('locale') === '2' ? 'en' : 'fr';
    }

    const [language, setLanguage] = React.useState<string>(initialLanguage);
    const changeLanguage = (lng: any) => {
        setLanguage(lng);
        sessionStorage.setItem('locale', lng === 'en' ? '2' : '1');
        i18n.changeLanguage(lng);
        window.location.reload();
    };

    if(i18n.language !== language) {
        if(i18n.language === 'en' || language === 'fr') {
            setLanguage(i18n.language);
        }
    }

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    let disclaimerClass = 'col-12 FooterDisclaimer d-inline-flex';
    if(!showDisclaimer) {
        disclaimerClass = 'col-12 FooterDisclaimer d-none';
    }
    console.log(i18n.language, 'i18n.language')

    const languageSelector = () => {
        if(hideLanguageSelect) return ('');
        return (
            <div className="LanguageContainer">
                <FormControl>
                    <Select
                        id="language-select"
                        value={i18n.language === 'fr' ? 'fr' : 'en'}
                        label="Language"
                        onChange={(event) => {
                            changeLanguage(event.target.value);
                        }}
                        variant={'standard'}
                    >
                        <MenuItem style={{fontSize: '14px', fontFamily: "Hellix !important", color: '#33383C'}} value={'en'}>{language === 'en' ? 'English' : 'Anglais'}</MenuItem>
                        <MenuItem style={{fontSize: '14px', fontFamily: "Hellix !important", color: '#33383C'}} value={'fr'}>{language === 'en' ? 'French' : 'Français'}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        );
    }

    if(!hideLanguageSelect) {
        return (
            <div>
                <div className="FooterDior row">
                    <div className="FooterSection col-12 col-md-4">
                        <h4>{t('footerDior.selectLanguage')}</h4>
                        {languageSelector()}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="row">
                <div className={disclaimerClass}>
                    <p>{t('footerDior.disclaimer')}</p>
                </div>
            </div>
            <div className="FooterDior row">
                <div className="FooterSection col-12 col-md-4">
                    <h4>{t('footerDior.clientService')}</h4>
                    <Link target={'_blank'} to={
                        locale === 'fr_fr' ? `https://www.dior.com/fr_fr/fashion/contact-couture` : `https://www.dior.com/${locale}/fashion/contact-couture`
                    }>{t('footerDior.contactUs')}</Link>
                </div>
                <div className="FooterSection col-12 col-md-4 mt-5 mt-md-0">
                    <h4>{t('footerDior.houseOfDior')}</h4>
                    <a target='_blank' href='/documents/Terms_Conditions.pdf'>{t('footerDior.terms')}</a>
                    <Link target={'_blank'} to={
                        locale === 'fr_fr' ? `https://www.dior.com/fr_fr/fashion/mentions-legales#mentions-legales` : `https://www.dior.com/${locale}/fashion/legal-terms`
                    }>{t('footerDior.mentions')}</Link>
                    <a target='_blank' href='/documents/Privacy_Policy.pdf'>{t('footerDior.policy')}</a>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(FooterDior)
