import Header from "../Header";
import SearchBar from "../search-engine/SearchBar";
import jwt from "jwt-decode";
import {checkPathName} from "../../global";
import React, {useState, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import dataProvider from "../../provider/api-interface";
import {Loader} from "../Loader";

import {setFilterSortProducts} from "../search-engine/Utils";

import './Catalogue.css';


function Catalogue() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const rolePathName = window.location.pathname.split('/')[1];
    const [pathName, setPathName] = React.useState<string>(rolePathName);
    const [searchParams, setSearchParams] = useSearchParams();

    const [wishlist, setWishlist] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<any>('');
    const [products, setProducts] = useState<any>(null);
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);

    const listCode = searchParams.get('id');
    const categoryId = searchParams.get('categoryId');
    const [activeFilters, setActiveFilters] = useState<any>(searchParams.get('filter'));
    const [activeSort, setActiveSort] = useState<any>(searchParams.get('sort'));

    const [scrollPosition, setScrollPosition] = useState(localStorage.getItem('scrollPosition') !== null ? parseInt(localStorage.getItem('scrollPosition') as string) : 0);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const token: any = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    //Use anywhere
    if(!checkPathName(pathName, token)) {
        navigate('/404');
    }

    const jwtToken = localStorage.getItem('token') === null ? sessionStorage.getItem('token') : localStorage.getItem('token');
    const decodedUser = jwt(jwtToken!);

    const [user, setUser] = useState<any>(decodedUser);
    //End use anywhere
    React.useEffect(() => {
        console.log(query, 'QUERY');
        const fetchWishlist = async () => {
            //console.log(listCode, 'listCode')
            await dataProvider.getWishlistByListCode(listCode, jwtToken).then((response: any) => {
                if(response !== null) {
                    //console.log(response, 'wishlist in catalogue page')
                    setWishlist(response);
                    fetchAllProducts(response.boutique.dior_locale_id, -1, query);
                } else {
                    console.log('error');
                }
                //return response;
            }).catch((error: any) => {
                console.log(error);
                //navigate(`/${pathName}/404`);
            })
        }

        fetchWishlist();
    }, [listCode]);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
        console.log(scrollPosition, 'scrollPosition');
        localStorage.setItem('scrollPosition', scrollPosition.toString());
    }

    useEffect(() => {
        if(isScrolled || localStorage.getItem('scrollPosition') === null) {
            window.addEventListener('scroll', handleScroll, {passive: true});
        }
    });

    const fetchAllProducts = async (localeId: any, items=-1, query: any) => {
        //let languageParam = '';
        //if(user.role === 2) {
        let chosenLocale = sessionStorage.getItem('locale') !== null ? sessionStorage.getItem('locale') : user.locale_id;
        if(chosenLocale === undefined ||
            chosenLocale === null ||
            chosenLocale === '' ||
            chosenLocale === 'null' ||
            chosenLocale === 'undefined') {
            chosenLocale = 2;
        }

        let languageParam = `,"chosen_dior_locale_id":${parseInt(chosenLocale)}`;

        let filterSort: any = {
            filter: '',
            sort: '',
            search: ''
        };

        if(activeFilters !== null) {
            filterSort = setFilterSortProducts(activeFilters, '');
        } else if (activeSort !== null) {
            filterSort = setFilterSortProducts('', activeSort);
        } else if(activeFilters !== null && activeSort !== null) {
            filterSort = setFilterSortProducts(activeFilters, activeSort);
        }

        console.log(filterSort, 'filterSort');
        let queryString = `?filter={"dior_locale_id" : ${localeId}, "main_category_id" : ${categoryId}${languageParam}${filterSort.filter}${filterSort.search}}${filterSort.sort}&perPage=${items}`;
        if (query === '') {
            setQuery(queryString);
        }
        await dataProvider.get(`product/${query !== '' ? query : queryString}`, jwtToken).then((response: any) => {
            if(response !== null){
                setProducts(response);
            } else {
                console.log('error')
            }
        });
    }


    const updateQuery = (newQuery: any) => {
        setQuery(newQuery);
        fetchAllProducts(wishlist.boutique.dior_locale_id, -1, newQuery);
    };

    const updateFilters = (newFilters: any) => {
        setActiveFilters(newFilters);
    }

    const updateSort = (newSort: any) => {
        setActiveSort(newSort);
    }

    useEffect(() => {
        if (imagesLoaded && localStorage.getItem('scrollPosition') !== null) {
            let tempScrollPosition = parseInt(localStorage.getItem('scrollPosition') as string);
            window.scrollTo(0, tempScrollPosition+60); //60 is the height of the header
            setTimeout(() => {setIsScrolled(true)}, 1000);
        }

    }, [imagesLoaded]);

    //console.log(wishlist, 'wishlist in catalogue page')
    //console.log(wishlist !== null ? wishlist.products : null, 'wishlistProducts in catalogue page')
    //console.log(products, 'products in catalogue page')
    if(wishlist === null) {
        return <div style={{height: "100vh", width: "100%"}}><Loader/></div>;
    } else {
        if(products === null) {
            return <div style={{height: "100vh", width: "100%"}}><Loader/></div>;
        } else {
            let wishlistUrl = null;
            if(user.role === 2 || user.role === 3){
                wishlistUrl = `/${pathName}/registry/${listCode}`;
            }

            return (
                <div className="Catalogue" style={{minHeight: `${scrollPosition+60}px`}}>
                    <Header /*hideLanguageSelect={user.role === 2}*/
                        link={`/${pathName}/registry/categories?id=${listCode}`} textLink={t('header.home')} wishlistUrl={wishlistUrl}/>
                    <div className="row no-gutter">
                        <div className="col-12 col-lg-3 d-md-block d-none">
                        </div>
                        <div className="CatalogueContainer col-12 col-lg-6">
                            <div className="CatalogueTitle">
                            <span><strong
                                style={{fontFamily: 'Atacama VAR', color: '#33383C !important', fontSize: '16px', fontWeight: '400'}}>{t('catalogue')}</strong> <span style={{color: '#7B8487'}}>{products?.total} {t('productsAvailableTitle')}</span></span>
                            </div>
                            {<SearchBar
                                products={products}
                                category={categoryId}
                                wishlist={wishlist}
                                locale={wishlist !== null ? wishlist.boutique.dior_locale_id : null}
                                isLoading={isLoading}
                                routeType={'catalogue'}
                                searchQuery={'products'}
                                activeSort={activeSort}
                                activeFilters={activeFilters}
                                setQuery={updateQuery}
                                setFilters={updateFilters}
                                setSort={updateSort}
                                setImagesLoaded={setImagesLoaded}
                                token={jwtToken}/>}
                        </div>
                        <div className="col-12 col-lg-3 d-md-block d-none">
                        </div>
                    </div>
                </div>
            );
        }
    }


}

export default withTranslation()(Catalogue);

