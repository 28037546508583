import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';

import { mainRolesRoutes } from './global';

import Home from './components/pages/Home';
import CreateWishlist from "./components/pages/CreateWishlist";
import CreateGiftee from "./components/pages/CreateGiftee";
import Wishlist from "./components/pages/Wishlist";
import CreateMessage from "./components/pages/CreateMessage";
import Message from "./components/pages/Message";
import Catalogue from "./components/pages/Catalogue";

import Landing from "./components/pages/Landing";
import PublicRegistration from "./components/pages/PublicRegistration";

import Login from './components/auth/Login';
import Register from "./components/auth/Register";
import LoginSuccess from "./components/auth/LoginSuccess";
import CourtesyPage from "./components/auth/CourtesyPage";
import GifterLogin from "./components/auth/GifterLogin";
import LoginSignup from "./components/auth/LoginSignup";
import GetStarted from "./components/pages/GetStarted";

import PrivateRoute from './components/routing/PrivateRoute';
import ProductsList from "./components/pages/ProductsList";
import Product from "./components/pages/Product";
import PageNotFound from "./components/pages/PageNotFound";
import { useTranslation } from 'react-i18next';
import Cookies from "js-cookie";
import GifterDetails from "./components/pages/GifterDetails";
import ResetPassword from "./components/auth/ResetPassword";

import {Modal} from "react-bootstrap";
import Switch from '@mui/material/Switch';
import NewPassword from "./components/auth/NewPassword";
import Categories from "./components/pages/Categories";
import PublicRegistrationSuccess from "./components/pages/PublicRegistrationSuccess";

function App(props: any) {
    const { t, i18n } = useTranslation();

    const [showCookieModal, setShowCookieModal] = React.useState<boolean>(false);

    const handleCloseCookieModal = () => setShowCookieModal(false);
    const handleShowCookieModal = () => setShowCookieModal(true);

    const handleConfirmCookies = () => {
        Cookies.set('cookiesAccepted', 'true');
        handleCloseCookieModal();
    }

    const handleRejectCookies = () => {
        Cookies.set('cookiesAccepted', 'false');
        handleCloseCookieModal();
    }

    /*React.useEffect(() => {
        if (Cookies.get('cookiesAccepted') === undefined && Cookies.get('auth') === 'true') {
            handleShowCookieModal();
        }
    });*/
    const cookieModal = () => {
        return  (<Modal className="ModalCookie" show={showCookieModal} onHide={handleCloseCookieModal}>
            <Modal.Header closeButton>
                <Modal.Title>{t('modalCookieTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="ModalCookieContainer">
                    <div className="EssentialCookiesContainer">
                        <p>{t('modalCookieParagraph')}</p>
                        <Switch defaultChecked disabled={true} />
                    </div>
                    <div className="CookieChoicesContainer">
                        <p>{t('modalCookieChoices')}</p>
                        <Switch defaultChecked disabled={false} />
                    </div>
                    <div className="ConfirmCookies" onClick={() => {handleConfirmCookies()}}>
                        <button className="Fgift-button">{t('confirmCookieChoices')}</button>
                    </div>
                    <div className="CancelCookies" onClick={() => {handleRejectCookies()}}>
                        <button className="Fgift-button" style={{backgroundColor: '#fff', color: '#000'}}>{t('rejectCookieChoices')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>)
    }

    return (
        <Container className="DiorContainer" style={{position: 'relative', padding: '0 !important', backgroundColor: '#F8F8F8'}} maxWidth={false}>
            <Router>
                <Routes>
                    {/** Landing TODO add :locale instead of fr_fr **/}
                    {/*<Route path='/fr_fr/baby-gift-list' element={<Landing />} />
                    <Route path='/en_gb/baby-gift-list' element={<Landing />} />*/}
                    {/*<Route path='/en_us/baby-gift-list' element={<Landing />} />
                    <Route path='/en_ae/baby-gift-list' element={<Landing />} />*/}
                    <Route path='/fr_fr/baby-gift-list' element={<LoginSignup />} />
                    <Route path='/en_gb/baby-gift-list' element={<LoginSignup />} />
                    {/** Register routes **/}
                    <Route path='/get-started/:userId' element={<GetStarted />} />
                    <Route path='/confirm-registration/:token' element={<Register />} />
                    <Route path='/:locale/registration-success' element={<PublicRegistrationSuccess />} />
                    <Route path='/' element={<LoginSignup />} />
                    {/** Login **/}
                    <Route path='/login' element={<LoginSignup />} />
                    {/** Reset password **/}
                    <Route path={`/reset-password`} element={<ResetPassword />} />
                    <Route path={`/set-new-password/:token`} element={<NewPassword />} />
                    {/**GIFTER TEMP COURTESY PAGE**/}
                    {/*<Route path={`${mainRolesRoutes.gifter}/:wishlistCode`} element={<CourtesyPage />} />*/}
                    {/**GIFTER LOGIN**/}
                    <Route path={`${mainRolesRoutes.gifter}/:wishlistCode`} element={<GifterLogin />} />
                    {/**GIFTER FINALIZE GIFT**/}
                    <Route path={`/finalize-gift/:wishlist_product_id`} element={<CreateMessage/>}/>
                    {/** Protected routes **/}
                    <Route path={`/${mainRolesRoutes.salesAssistant}`} element={<PrivateRoute />}>
                        <Route path={`/${mainRolesRoutes.salesAssistant}`} element={<Home/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/create-registry`} element={<CreateWishlist/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/create-giftee`} element={<CreateGiftee/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/giftee/edit/:id`} element={<CreateGiftee/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/registry/edit/:id`} element={<CreateWishlist/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/registry/:id`} element={<Wishlist/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/registry/categories`} element={<Categories/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/registry/catalogue`} element={<Catalogue/>}/>
                        {/*<Route path={`/${mainRolesRoutes.salesAssistant}/registry/create-message`} element={<CreateMessage/>}/>*/}
                        <Route path={`/${mainRolesRoutes.salesAssistant}/registry/:id/products`} element={<ProductsList/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/product`} element={<Product/>}/>
                        <Route path={`/${mainRolesRoutes.salesAssistant}/gifter-details`} element={<GifterDetails/>}/>
                    </Route>
                    <Route path={`/${mainRolesRoutes.giftee}`} element={<PrivateRoute />}>
                        <Route path={`/${mainRolesRoutes.giftee}/login-success`} element={<LoginSuccess />} />
                        <Route path={`/${mainRolesRoutes.giftee}/registry/:id`} element={<Wishlist/>}/>
                        {/*<Route path={`/${mainRolesRoutes.giftee}/registry/edit/:id`} element={<CreateWishlist/>}/>*/}
                        <Route path={`/${mainRolesRoutes.giftee}/registry/categories`} element={<Categories/>}/>
                        <Route path={`/${mainRolesRoutes.giftee}/registry/catalogue`} element={<Catalogue/>}/>
                        <Route path={`/${mainRolesRoutes.giftee}/product`} element={<Product/>}/>
                        <Route path={`/${mainRolesRoutes.giftee}/registry/message`} element={<Message/>}/>
                    </Route>
                    <Route path={`/${mainRolesRoutes.callCenter}`} element={<PrivateRoute />}>
                        <Route path={`/${mainRolesRoutes.callCenter}/`} element={<Home/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/create-registry`} element={<CreateWishlist/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/create-giftee`} element={<CreateGiftee/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/giftee/edit/:id`} element={<CreateGiftee/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/registry/edit/:id`} element={<CreateWishlist/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/registry/:id`} element={<Wishlist/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/registry/categories`} element={<Categories/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/registry/catalogue`} element={<Catalogue/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/registry/:id/products`} element={<ProductsList/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/product`} element={<Product/>}/>
                        <Route path={`/${mainRolesRoutes.callCenter}/gifter-details`} element={<GifterDetails/>}/>
                    </Route>

                    <Route path={`/${mainRolesRoutes.gifter}/registry/:id/:gifterId`} element={<Wishlist/>}/>
                    <Route path={`/${mainRolesRoutes.gifter}`} element={<PrivateRoute />}>
                        <Route path={`/${mainRolesRoutes.gifter}/registry/:id`} element={<Wishlist/>}/>
                        <Route path={`/${mainRolesRoutes.gifter}/product`} element={<Product/>}/>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Router>
            {cookieModal()}
        </Container>
    );
}

export default App;
